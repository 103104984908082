import './home.css';



function Home( props ) {
    const { title, first_name, socialMediaLinks, button } = props;

    return <>
        <section className="home" id="home">
            <div className="container">
                {/* intro */}
                <div className="intro">
                    {/* avatar image */}
                    <img src="/assets/img/avatar-2.svg" alt="Nymul Islam" />
                    {/* info */}
                    <h1>{ first_name }</h1>
                    <span>I'm a <span className="info-text">{ title }</span></span>
                    {/* social icons */}
                    <ul className="social-icons">
                        {
                            socialMediaLinks.map(link => (
                                <li key={link.id}>
                                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                                        <i className={link.icon}></i>
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                    {/* button */}
                    <div>
                        <a href={ button.link } className="btn btn-default">{ button.name }</a>
                    </div>
                    {/*scroll down mouse wheel*/}
                    <div className="scroll-down">
                        <a href="" className="mouse-wrapper">
                            <span>Scroll Down</span>
                            <span className="mouse"><span className="wheel"></span></span>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </>
}

export default Home;