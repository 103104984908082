function EduItem( props ) {

    const { id, name, start_date, end_date, desc } = props

    const start_year    = new Date( start_date );
    const end_year      = new Date( end_date );
    // console.log( completionDate.getFullYear() );
    
    return <>
        <div className="timeline-item" key={id}>
            <i className="lni lni-graduation timeline-icon"></i>
            <h3 className="timeline-date">
                <i className="lni lni-calendar"></i> { start_year.getFullYear() } - { end_year.getFullYear() }
            </h3>
            <h3 className="timeline-title">{ name }</h3>
            <p className="timeline-text">{ desc }</p>
        </div>
    </>

}

export default EduItem;