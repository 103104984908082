import './About.css';
import Facts from "./Facts";
import Content from "./Content";

function About( props ) {

    const{ description, button, skills } = props

    return <>
        <section className="about section" id="about">
            <div className="container">
                {/*section title*/}
                <h2 className="section-title padd-15">About Me</h2>
                <Content description={ description } button={ button } skills={ skills } />
                <div className="row">
                    {/*fact item*/}
                    <Facts number={'10'} name={'Projects Done'} />

                    <Facts number={'50'} name={'Cup of Coffee'} />

                    <Facts number={'10'} name={'Satisfied Clients'} />

                    <Facts number={'500+'} name={'Problem Solving'} />

                </div>
            </div>
        </section>
    </>
}

export default About;