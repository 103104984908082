function Prices( props ) {

    const { id, title, img, status, amount, support_type, link, desc } = props;

    let className = 'price-item';

    if ( status == 1) {
        className = 'price-item best';
    }

    const image = `assets/img/${img}`;

    return <>
        <div className="prices">
            <div className={ className }>
                { status === 1  && <span className="badge">Recommended</span> }
                <img src={ image } alt="" />
                <h3 className="plan">{ title }</h3>
                <p>{ desc }</p>
                <p>{ support_type }</p>
                <h3 className="price"><em>$</em>{ amount }<span>Month</span></h3>
                <a href={ link } className="btn btn-default">Get Started</a>
            </div>
        </div>
    </>
}
export default Prices;