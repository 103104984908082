function ProfeItem( props ) {

    const { id, title, desc } = props;

    return <>
        <div className="timeline-item" key={id}>
            <i className="lni lni-briefcase timeline-icon"></i>
            <h3 className="timeline-date">
                <i className="lni lni-calendar"></i> 2023 - present
            </h3>
            <h3 className="timeline-title">{ title }</h3>
            <p className="timeline-text">{ desc }</p>
        </div>
    </>
}

export default ProfeItem;