import './header.css';

function Header() {
    return <>
        <header className="header" id="header">
            <nav className="nav container padd-15">

                <a href="" className="nav-logo">
                    <h2>Moon</h2>
                </a>

                <div className="nav-menu" id="nav-menu">

                    <ul className="nav-list">
                        <li className="nav-item">
                            <a href="#home" className="nav-link active-link">Home</a>
                        </li>
                        <li className="nav-item">
                            <a href="#about" className="nav-link">About</a>
                        </li>
                        <li className="nav-item">
                            <a href="#experience" className="nav-link">Experience</a>
                        </li>
                        <li className="nav-item">
                            <a href="#pricing" className="nav-link">Pricing</a>
                        </li>
                        <li className="nav-item">
                            <a href="#portfolio" className="nav-link">Portfolio</a>
                        </li>
                        <li className="nav-item">
                            <a href="blog.html" className="nav-link">Blog</a>
                        </li>
                        <li className="nav-item">
                            <a href="#contact" className="nav-link">Contact</a>
                        </li>
                    </ul>

                    <div className="nav-close" id="nav-close">
                        <i className="lni lni-close"></i>
                    </div>
                </div>

                <div className="nav-btns">

                    <i className="lni lni-pallet change-theme" id="theme-button"></i>

                    <div className="nav-toggle" id="nav-toggle">
                        <i className="lni lni-grid-alt"></i>
                    </div>
                </div>
            </nav>

        </header>
    </>
}

export default Header;