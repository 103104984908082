import './Experience.css';
import Education from "./Education";
import Professional from "./Professional";

function Experience( props ) {

    const { education, profession } = props;

    return <>
        <section className="section" id="experience">
            <div className="container">
                {/*section title*/}

                <h2 className="section-title padd-15">Experience</h2>
                <div className="row">
                    {/*Education*/}
                    <Education educations={ education } />
                    {/*Experience*/}
                    <Professional profession={profession} />
                </div>
            </div>
        </section>
    </>
}

export default Experience;