function Items( props ) {

    const { id, img, title, desc } = props;

    const style = [
        { background: "rgb(108,108,229)" },
        { background: "rgb(249,215,76)" },
        { background: "rgb(249,123,139)" }
    ]

    const image = `assets/img/${ img }`;

    return <>
        <div className="service-item padd-15">
            <div className="service-item-inner" style={ style[ id - 1] }>
                <img src={ image } alt="" />
                <h3>{ title }</h3>
                <p>{ desc }</p>
            </div>
        </div>
    </>
}

export default Items;