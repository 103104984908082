import './Testimonial.css';
import Item from "./Item";
import { Swiper, SwiperSlide } from 'swiper/react';
import '../../css/swiper.css';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function Testimonial( props ) {

    const { reviews } = props;

    return <>
        <section className="section">
            <div className="container">
                {/*section title*/}
                <h2 className="section-title padd-15">Clients & Reviews</h2>
                {/*testimonial wrapper*/}
                <div className="swiper testimonial-wrapper">
                    <div className="swiper-wrapper">
                        {/*testimonial item*/}
                        <Swiper
                            spaceBetween={30}
                            centeredSlides={true}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={false}
                            modules={[Autoplay, Pagination, Navigation]}
                            className="mySwiper"
                        >
                            {/* Map through your testimonials here */}
                            { reviews.map( review => (
                                <SwiperSlide className="testimonial-item" key={review.id}>
                                    <Item name={review.name} title={review.title} img={review.img} comment={review.comment} />
                                </SwiperSlide>
                                ) ) }
                            {/* Repeat SwiperSlide for each testimonial */}
                        </Swiper>

                    </div>
                    <div className="swiper-pagination"></div>
                </div>
            </div>
        </section>
    </>
}

export default Testimonial;