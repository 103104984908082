import ProfeItem from "./ProfeItem";

function Professional( props ) {

    const { profession } = props;

    return <>
        <div className="experience padd-15">
            <div className="row">
                <div className="timeline-box">
                    <div className="timeline">

                        {/*timeline item*/}

                        { profession.map( item => (
                            <ProfeItem key={ item.id } title={ item.title } desc={ item.desc } />
                        ) ) }

                        {/*<div className="timeline-item">*/}
                        {/*            <i className="lni lni-briefcase timeline-icon"></i>*/}
                        {/*            <h3 className="timeline-date">*/}
                        {/*                <i className="lni lni-calendar"></i> 2021 - 2023*/}
                        {/*            </h3>*/}
                        {/*            <h3 className="timeline-title">Junior Software Engineer</h3>*/}
                        {/*            <p className="timeline-text">I have been working in Speeddigit Software Solution*/}
                        {/*                as a junior software engineer. Here we were working on an ERP</p>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Professional;