import {SwiperSlide} from "swiper/react";
import '../../css/swiper.css';

function Item( props ) {

    const { name, title, img, comment } = props;

    const image = `assets/img/${img}`;

    return <>

            <div className="thumb">
                <img src={image} alt={name} />
            </div>
            <h3>{name}</h3>
            <span className="subtitle">Product designer at Dribble</span>
            <div className="comment">
                I enjoy working the theme and learn so much. You guys make the process fun and interesting. Good Luck!
            </div>
    </>
}

export default Item;