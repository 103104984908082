function Content( props ) {

    const{ description, button, skills } = props

    return <>

        <div className="row">
            <div className="about-img padd-15">
                {/*avatar img*/}
                <img src="assets/img/avatar-2.svg" alt="" />
            </div>
            <div className="about-content padd-15">
                <div className="rounded">
                    <div className="row">
                        <div className="about-text padd-15">
                            {/*about text*/}
                            <p>{ description }</p>
                            <div>
                                <a href={ button.link } className="btn btn-default">{ button.name }</a>
                            </div>
                        </div>
                        <div className="skills padd-15">
                            <div className="row">
                                {/* skill item */}
                                {
                                    skills.map( skill => (
                                        <div className="skill-item" key={skill.id}>
                                            <h4>{ skill.name }</h4>
                                            <div className="progress">
                                                <div className="progress-in"
                                                     style={{ width: skill.percent, background: skill.hex }}></div>
                                                <div className="skill-percent">{ skill.percent }</div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Content;