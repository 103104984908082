import './Blog.css';

function Blog() {
    return <>
        {/*Blog*/}
        <section class="section">
            <div class="container">
                {/*section title*/}
                <h2 class="section-title padd-15">Latest Posts</h2>
                <div class="row">
                    {/*blog item*/}
                    <div class="blog-item padd-15">
                        <div class="inner">
                            <div class="thumb">
                                <a href="#"><span class="category">Reviews</span></a>
                                <a href="#"><img src="assets/img/1.svg" alt="" /></a>
                            </div>
                            <div class="details">
                                <h3 class="title"><a href="#">5 Best App Development Tool for Your Projects</a></h3>
                                <ul class="meta">
                                    <li>09 February, 2022</li>
                                    <li>Bolby</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/*blog item*/}
                    <div class="blog-item padd-15">
                        <div class="inner">
                            <div class="thumb">
                                <a href="#"><span class="category">Tutorial</span></a>
                                <a href="#"><img src="assets/img/2.svg" alt="" /></a>
                            </div>
                            <div class="details">
                                <h3 class="title"><a href="#">Common Misconceptions About Payment</a></h3>
                                <ul class="meta">
                                    <li>07 February, 2022</li>
                                    <li>Bolby</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/*blog item*/}
                    <div class="blog-item padd-15">
                        <div class="inner">
                            <div class="thumb">
                                <a href="#"><span class="category">Business</span></a>
                                <a href="#"><img src="assets/img/1.svg" alt="" /></a>
                            </div>
                            <div class="details">
                                <h3 class="title"><a href="#">3 Things to know about startup business</a></h3>
                                <ul class="meta">
                                    <li>09 February, 2022</li>
                                    <li>Bolby</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
}

export default Blog;