function ContactInfo( props ) {

    const { id, title, content } = props;

    return <>
        <div className="contact-information" key={id}>
            <h3 className="contact-subtitle">{ title }</h3>
            <span className="contact-description">
                <i className="fa-duotone fa-phone"></i>
                { content }
            </span>
        </div>
    </>
}

export default ContactInfo;