import Header from './components/header/Header';
import Home from './components/home/home'
import About from "./components/about/About";
import Service from "./components/service/Service";
import Experience from "./components/experience/Experience";
import Pricing from "./components/Pricing/Pricing";
import Portfolio from "./components/portfolio/Portfolio";
import Testimonial from "./components/testimonial/Testimonial";
import Blog from "./components/blog/Blog";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";
import DB from './database/db.json';


const home          = DB[0].home;
const about         = DB[0].about;
const services      = DB[0].services;
const education     = DB[0].education;
const profession    = DB[0].profession;
const reviews       = DB[0].reviews;
const contacts      = DB[0].contact;
const pricing       = DB[0].pricing;

function App() {
  return (
    <>
        <Header />
        <main className="main-content">
            <Home title={ home['title'] } first_name={ home['first_name'] } socialMediaLinks={ home['social_media_links'] } button={ home['button'] } />
            <About description={ about.description } button={ about.button } skills={ about.skills }/>
            <Service services={ services } />
            <Experience education={education} profession={profession} />
            <Pricing pricing={ pricing }/>
            <Portfolio />
            <Testimonial reviews={ reviews }/>
            <Blog />
            <Contact contacts={ contacts } />
            <Footer />
        </main>
    </>
  );
}

export default App;