import './Pricing.css';
import Prices from "./Prices";

function Pricing( props ) {
    
    const { pricing } = props;

    return <>
        <section className="section pricing" id="pricing">
            <div className="container">
                {/*section title*/}
                <h2 className="section-title padd-15">Pricing Plans</h2>
                <div className="row">
                    {/*prices*/}

                    { pricing.map( price => (
                        <Prices key={price.id}  id={price.id} title={ price.title } img={price.img} status={price.status} amount={price.amount} support_type={price.support_type} link={price.link} desc={ price.desc } />
                    ) ) }

                </div>
            </div>
        </section>
    </>
}

export default Pricing;