import './Footer.css';

function Footer() {
    return <>
        <footer className="footer">
            <div className="container">
                <div className="footer-text padd-15">
                    <p>&copy; nymul-islam-moon</p>
                </div>
            </div>
        </footer>
    </>
}

export default Footer;