import './Contact.css';
import ContactInfo from "./ContactInfo";

function Contact( props ) {

    const { id, title, contacts } = props;

    return <>
        <section className="contact section" id="contact">
            <div className="container">
                {/*section title*/}
                <h2 className="section-title padd-15">Contact With Me</h2>
                <div className="row">
                    {/*contact box*/}
                    <div className="contact-box padd-15">
                        <div className="contact-data">
                            {/*contact information*/}
                            { contacts.map( contact => (
                                <ContactInfo key={contact.id} title={contact.title} content={contact.content} />
                            ) ) }
                            {/*<div className="contact-information">*/}
                            {/*    <h3 className="contact-subtitle">Call me for instant support</h3>*/}
                            {/*    <span className="contact-description">*/}
                            {/*        <i className="fa-duotone fa-phone"></i>*/}
                            {/*        +880 178 628 7789*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                            {/*/!*contact information*!/*/}
                            {/*<div className="contact-information">*/}
                            {/*    <h3 className="contact-subtitle">Send me a mail</h3>*/}
                            {/*    <span className="contact-description">*/}
                            {/*        <i className="fa-duotone fa-paper-plane"></i>*/}
                            {/*        towkir1997islam@gmail.com*/}
                            {/*    </span>*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    {/*contact form*/}
                    <form className="contact-form padd-15" action="https://formspree.io/f/mbjnjvlb"
                          method="POST">
                        <div className="contact-inputs">
                            {/*contact content*/}
                            <div className="contact-content">
                                <input type="email" className="contact-input" name="email" id="email" />
                                    <label htmlFor="email" className="contact-label">Email</label>
                                    <span></span>
                            </div>
                            {/*contact content*/}
                            <div className="contact-content">
                                <input type="text" className="contact-input" name="subject" id="subject" />
                                    <label htmlFor="subject" className="contact-label">Subject</label>
                                    <span></span>
                            </div>

                            {/*contact content*/}
                            <div className="contact-content contact-area">
                                <textarea name="message" id="message" cols="30" rows="10"
                                          className="contact-input"></textarea>
                                <label htmlFor="message" className="contact-label">Message</label>
                                <span></span>
                            </div>
                        </div>
                        <button href="#" type="submit" className="btn btn-default">Send Message</button>
                    </form>
                </div>
            </div>
        </section>
    </>
}

export default Contact;