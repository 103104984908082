function Facts( props ) {

    const { number, name } = props;

    return <>
        <div className="fact-item padd-15">
            <i className="lni lni-thumbs-up"></i>
            <div className="details">
                <h3>{ number }</h3>
                <p>{ name } </p>
            </div>
        </div>
    </>
}

export default Facts;