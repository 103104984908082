import EduItem from "./EduItem";

function Education( props ) {

    const { educations } = props

    return <>
        <div className="education padd-15">
            <div className="row">
                <div className="timeline-box">
                    <div className="timeline">

                        {/*timeline item*/}
                        { props.educations.map( education => (
                            <EduItem key={education.id}  name={education.name} start_date={education.start_date} end_date={education.end_date} desc={education.desc} />
                        ) ) }

                    </div>
                </div>
            </div>
        </div>
    </>
}

export default Education;