import './Portfolio.css';

function Portfolio() {
    return <>
        <section className="portfolio section" id="portfolio">
            <div className="container">
                {/* section title */}
                <h2 className="section-title padd-15">Portfolio</h2>
                <div className="row">
                    <div className="portfolio-filter padd-15">
                        <div className="portfolio-filter-inner">
                            <button type="button" className="active" data-filter="all">Everything</button>
                            <button type="button" data-filter="art">Art</button>
                            <button type="button" data-filter="creative">Creative</button>
                            <button type="button" data-filter="design">Design</button>
                        </div>
                    </div>
                </div>
                <div className="row">

                    {/* portfolio item */}
                    <div className="portfolio-item padd-15" data-category="art">
                        <div className="portfolio-item-inner">
                            <div className="portfolio-item-thumbnail">
                                <img src="assets/img/1.svg" alt="" />
                                    <div className="mask"></div>
                            </div>
                            <span className="term">Art , design</span>
                            <h3 className="portfolio-item-title">Project Management Illustration</h3>
                            <span className="more-button">&hellip;</span>
                        </div>
                    </div>

                    {/* portfolio item */}
                    <div className="portfolio-item padd-15" data-category="creative">
                        <div className="portfolio-item-inner">
                            <div className="portfolio-item-thumbnail">
                                <img src="assets/img/2.svg" alt="" />
                                    <div className="mask"></div>
                            </div>
                            <span className="term">Creative</span>
                            <h3 className="portfolio-item-title">Onboaeding Motivation</h3>
                            <span className="more-button">&hellip;</span>
                        </div>
                    </div>
                    {/* portfolio item */}
                    <div className="portfolio-item padd-15" data-category="design">
                        <div className="portfolio-item-inner">
                            <div className="portfolio-item-thumbnail">
                                <img src="assets/img/3.svg" alt="" />
                                    <div className="mask"></div>
                            </div>
                            <span className="term">Art , design</span>
                            <h3 className="portfolio-item-title">iMac Mockup Design</h3>
                            <span className="more-button">&hellip;</span>
                        </div>
                    </div>
                    {/*// <!-- portfolio item -->*/}
                    <div className="portfolio-item padd-15" data-category="creative">
                        <div className="portfolio-item-inner">
                            <div className="portfolio-item-thumbnail">
                                <img src="assets/img/4.svg" alt="" />
                                    <div className="mask"></div>
                            </div>
                            <span className="term">Creative</span>
                            <h3 className="portfolio-item-title">RAX, Python Project</h3>
                            <span className="more-button">&hellip;</span>
                        </div>
                    </div>

                    {/*// <!-- portfolio item -->*/}
                    <div className="portfolio-item padd-15" data-category="art">
                        <div className="portfolio-item-inner">
                            <div className="portfolio-item-thumbnail">
                                <img src="assets/img/5.svg" alt="" />
                                    <div className="mask"></div>
                            </div>
                            <span className="term">Creative</span>
                            <h3 className="portfolio-item-title">Guest App Walkthrough Screens</h3>
                            <span className="more-button">&hellip;</span>
                        </div>
                    </div>

                    {/* portfolio item */}
                    <div className="portfolio-item padd-15" data-category="creative">
                        <div className="portfolio-item-inner">
                            <div className="portfolio-item-thumbnail">
                                <img src="assets/img/6.svg" alt="" />
                                    <div className="mask"></div>
                            </div>
                            <span className="term">Design</span>
                            <h3 className="portfolio-item-title">Delivery App Wireframe</h3>
                            <span className="more-button">&hellip;</span>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </>
}

export default Portfolio;