import './Service.css';
import Items from "./Items";

function Service( props ) {

    return <>
        <section className="services section">
            <div className="container">
                {/*section title*/}
                <h2 className="section-title padd-15">Services</h2>
                <div className="row">
                    { /*services item*/ }
                    {
                        props.services.map( service => (
                            <Items key={ service.id } id={service.id} img={ service.img } title={ service.title } desc={ service.desc } />
                        ) )
                    }
                </div>
                <div className="text padd-15">
                    <p>Looking for a custom job? <a href="#">Click here</a> to contact me!</p>
                </div>
            </div>
        </section>
    </>
}
export default Service;